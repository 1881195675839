<template>
    <Layout>
        <div class="container-exercises">
            <h1 class="headline-semibold-24">{{ $t("banco_exercicios.title") }}</h1>
            <CategoriesNav :data="getAllCategories" :loading="getIsLoading" :defaultSelected="getSelectedCategory"
                @setSelectedCategory="setSelectedCategory" />
            <SubjectsList :data="getAreasFromCategory" :category="getSelectedCategory" :loading="getIsLoading" />
        </div>
    </Layout>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Layout: () =>
            import(
                "@/layout/Layout.vue"
            ),
        CategoriesNav: () =>
            import(
                "@/components/common/CategoriesNav.vue"
            ),
        SubjectsList: () =>
            import(
                "@/components/pages/exercises/SubjectsList.vue"
            ),
    },
    computed: {
        ...mapGetters({
            getMe: "auth/getMe",
            getExercisesAreas: "exercises/getExercisesAreas",
            getAllCategories: "exercises/getAllCategories",
            getIsMobile: "layout/getIsMobile",
            getIsLoading: "config/getIsLoading",
            getSelectedCategory: "exercises/getSelectedCategory",
            getAreasFromCategory: "exercises/getAreasFromCategory",
        }),
        exercisesAllowed() {
            if (
                this.$store.getters['user/metasSite'] &&
                this.$store.getters['user/metasSite'].exercises_allowed
            ) {
                return this.$store.getters['user/metasSite'].exercises_allowed === "1";
            } else return null;
        },
    },
    async created() {
        if (!this.exercisesAllowed) {
            this.$router.push({
                name: "Home",
            });
        }
        const area = this.$route.params.area;
        await this.actionFetchMe();
        await this.actionFetchExercisesAreas(area);
        this.setSelectedCategory(this.getAllCategories.find((category) => category.url === area));

    },
    data() {
        return {

        }
    },
    methods: {
        ...mapActions({
            actionSetIsLoading: "config/actionSetIsLoading",
            actionFetchExercisesAreas: "exercises/actionFetchExercisesAreas",
            actionFetchMe: "auth/actionFetchMe",
            actionSetSelectedCategory: "exercises/actionSetSelectedCategory",
        }),
        setSelectedCategory(category) {
            this.$router.push({
                name: "exercises_area",
                params: {
                    area: category.url,
                },
            });
            this.actionSetSelectedCategory(category);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.container-exercises {
    padding-top: 3.69rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 8.31rem;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }

    h1 {
        color: var(--fontcolor)
    }
}
</style>
  